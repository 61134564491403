import React, { useEffect, useContext, useState, useRef } from 'react'
import { Container, Flex, Box, Text, Button } from 'theme-ui'
import ContentContainer from '@solid-ui-components/ContentContainer'
import Divider from '@solid-ui-components/Divider'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import useForm from '@helpers/useForm'
import { FormContext } from '@solid-ui-components/ContentForm'
import httpService from '../../../../../../site/src/service/httpService'

import loadsGif from '../../../assets/loads.gif'
import { ModalContext } from '@solid-ui-components/Modal'

const styles = {
  combo: {
    p: 0.4,
    pl: 3,
    flex: [`100%`, null, null, 1],
    border: 'transparent'
  },

  bxStyleAd: {
    width: '30%',
    px: [1]
  },

  bxStyleBirim: {
    width: '25%',
    px: [2]
  },
  // wrapper2: {
  //   flex: [`100%`, null, null, 1],
  //   minWidth: 500,
  //   maxWidth: [`none`, null, null, 675],
  //   p: 2,
  //   height: "550px"
  // },
  wrapper1: {
    flex: [`100%`, null, null, 1],
    minWidth: 450,
    maxWidth: [`none`, null, null, 450],
    p: 2,
    textAlign: `center`
  },
  wrapper2: {
    flex: [`100%`, null, null, 1],
    minWidth: 1000,
    maxWidth: [`none`, null, null, 1000],
    p: 2
  },
  card: {
    overflow: `hidden`,

    margin: "15px"
  },
  content: {
    alignItems: `stretch`,
    flexDirection: [`row`, null, null, `column`],
    height: `full`
  },
  body: {
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `center`,
    flex: 1,
    p: 4
  },
  footerWrapper: {
    alignItems: `center`
  },
  postInfo: {
    flex: 1,
    flexWrap: `wrap`,
    justifyContent: `space-between`,
    color: `omega`,
    ml: 3
  },
  imageWrapper: {
    textAlign: `center`,
    position: `relative`,
    display: `block`,
    height: `full`
  },
  image: {
    display: [`none`, `block`],
    height: `full`,
    bg: `omegaLighter`,
    borderRadius: `default`,
    borderWidth: 0,
    minHeight: `11rem`,
    div: {
      p: `0 !important`
    }
  },
  avatar: {
    size: 42,
    bg: `omegaLighter`,
    borderRadius: `full`,
    borderStyle: `solid`,
    borderWidth: `md`,
    borderColor: `omegaLighter`,
    boxSizing: `content-box`,
    img: {
      objectPosition: 'top center !important'
    }
  },

  textVisible: {
    visible: 'false',
  },


  modalContent2: {
    borderRadius: `lg`,
    bg: `contentBg`,
    maxWidth: `container`,
    overflow: `hidden`,
    py: 4,
    px: 4
  },
  close: {

    position: `absolute`,
    // top: [3, null, -5],
    // left: [2, null, null, -5],
    size: [`icon.sm`, null, `icon.md`],
    zIndex: 99,
    width: 50,
    height: 50

  },
  wrappermlz: {
    // flex: [`100%`, null, null, 1],
    // minWidth: 500,
    // maxWidth: [`none`, null, null, 675],
    p: 0.5
  }
}



const MesajNo = ({ ...props }) => {

  const { handleSubmit, submitting, success } = useForm()
  const { formValues, setFormValues } = useContext(FormContext)
  const { setActiveModal } = useContext(ModalContext)

  const [btnID, setbtnID] = useState("");
  const formId = "frmMesajNo"






  useEffect(async () => {
    if (typeof window !== 'undefined') {
    
      let id = window.location.search?.replace("?i=", "");

      if (id.length == 24) {
        httpService.post("UnLocked/BildirimIptal", { params: id }).then((res) => {
          if (res.DogruMu) {
         
          } else {
           
          }
   
        }).catch((err) => {
        
        
        });
      } else {
       
      }
    }
  }, []);


  return (
    <Container>


        <Flex sx={{ flexWrap: `wrap`, justifyContent: `center`, m: -1 }}>
          <Box sx={styles.wrapper1}>
            <Box sx={{ flex: 1, py: [1, 1], px: [2, 3], textAlign: `center` }}>
              <Text as='h3' color='black'>
                Hoşgeldiniz 
              </Text> </Box>
            <ContentContainer
              content={{
                variant: "cards.interactive",
              }}
              variant='cards.interactive'
              sx={styles.card} >

              <Box sx={{ margin: "10px" }}>
                
                  <Box sx={{ flex: 1, py: [1, 1], px: [2, 3] }}>
                    <Text sx={{ marginTop: 15, marginLeft: 15, marginRight: 15 }} color="beta">Talebiniz üzerine bildirim gönderiminiz kapatılmıştır</Text>

                  </Box>
                <Divider space="3" />
               
                <Divider space="3" />
              </Box>
            </ContentContainer>

            <Box>
              <Text key={`lblicolcumesaj`} as='span' color='alpha' sx={{

              }} > <h2 onClick={e => {
                setActiveModal('authentication')
              }} style={{ cursor: "pointer", textDecoration: "underline" }}>

                  {"Giriş Yap"} </h2>      </Text>
            </Box>


            <Box>
              <Text key={`lblicolcumesaj`} as='span' color='grey' sx={{

              }} > <h3 onClick={e => {
                window.location.assign("/");
              }} style={{ cursor: "pointer", textDecoration: "underline" }}>

                  {"Ana Sayfaya Git"} </h3>      </Text>
            </Box>
          </Box>

        </Flex>
    </Container >
  )
}

export default WithDefaultContent(MesajNo)


