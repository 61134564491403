import React from 'react'
import { graphql } from 'gatsby'
import KtLayout from '@solid-ui-blocks/KtLayout/KtLayout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import MesajNo from '@solid-ui-blocks/MesajNo/MesajNo'


const MesajAlmakIstemiyorum = props => {

  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <KtLayout prp={props} cnt={content} lightmi={false} >
      <Seo title='Mesaj almak istemiyorum ' />
      <Divider space='5' />
      <MesajNo></MesajNo>
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
    </KtLayout>
  )
}

export const query = graphql`
  query innerpageMesajAlmakIstemiyorumPageBlockContent { 
    allBlockContent(filter: { page: { in: ["homepage/saas-v2", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    } 
  }
`


export default MesajAlmakIstemiyorum
